import Cookies from 'universal-cookie'

// TODO: Move these to @simplisafe/ss-ecomm-data/src/cookies/index.ts
export const COOKIE_DRUPAL_UID = 'DRUPAL_UID'
export const COOKIE_VID = 'vid'
export const COOKIE_ATAT_TOKEN = 'atat_v2'
export const COOKIE_LEAD_DATA = 'leadData'
export const COOKIE_PURCHASED_CART_ID = 'purchasedCartId'
export const COOKIE_FCP_ORDER_ID = 'FCP_ORDER_ID'
export const COOKIE_PREACTIVATION = 'ssOauthAccessToken'
export const COOKIE_CHAT_APP_ID = 'chat_app_id'
export const COOKIE_CHAT_OPENED = 'chat_opened'

export const cookies = new Cookies()

export const getVisitorId = () => cookies.get(COOKIE_VID)
export const getUserId = (): string => cookies.get(COOKIE_DRUPAL_UID)
export const getAtAtToken = (): string | undefined => cookies.get(COOKIE_ATAT_TOKEN)
export const getPurchasedCartId = (): string => cookies.get(COOKIE_PURCHASED_CART_ID)
export const getLeadData = () => cookies.get(COOKIE_LEAD_DATA)
export const getChatAppId = (): string => cookies.get(COOKIE_CHAT_APP_ID)
export const getChatOpened = (): string => cookies.get(COOKIE_CHAT_OPENED)



/**
 *
 *
 * TODO
 *
 *  switch everything over to use react-cookie
 *
 */
