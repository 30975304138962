import { currencyCode, getLocale } from '@package/utils/locale'
import { IOGetCart } from '@simplisafe/ss-ecomm-data/cart'
import { setCurrencyCode, setLocale } from '@simplisafe/ss-ecomm-data/locale/actions'
import React, {
  FC,
  ReactElement,
  useEffect,
  useLayoutEffect
} from 'react'
import { useDispatch } from 'react-redux'

type InizializeStoreProps = {
  readonly children: ReactElement
}

const InitializeStore: FC<InizializeStoreProps> = ({ children }: InizializeStoreProps) => {
  const dispatch = useDispatch()

  // Using useLayoutEffect to set locale/currency before any useEffect hooks run.
  useLayoutEffect(() => {
    // TODO this can just happen inside of redux as part of the initial store
    // TODO: fix the ts errors below (rules changed and started triggering linter errors)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    dispatch(setLocale(getLocale()))
    // TODO: fix the ts error below (rules changed and started triggering a linter error)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    dispatch(setCurrencyCode(currencyCode))
  }, [ dispatch ])

  useEffect(() => {
    dispatch(IOGetCart())
  }, [ dispatch ])
  return (
    <>
      {children}
    </>
  )
}

export default InitializeStore
