// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/DefaultPage.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-dynamic-package-page-tsx": () => import("./../../../src/templates/DynamicPackagePage.tsx" /* webpackChunkName: "component---src-templates-dynamic-package-page-tsx" */),
  "component---src-templates-quote-redirect-page-tsx": () => import("./../../../src/templates/QuoteRedirectPage.tsx" /* webpackChunkName: "component---src-templates-quote-redirect-page-tsx" */)
}

