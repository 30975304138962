import type { TrackingData } from '@simplisafe/ecomm-ts-types'
import isNilOrEmpty from '@simplisafe/ewok/ramda-adjunct/isNilOrEmpty'
import omitBy from '@simplisafe/ewok/ramda-adjunct/omitBy'
import { safeProp } from '@simplisafe/monda'
import {
  atatFetch,
  atatRequest
} from '@simplisafe/ss-ecomm-data/simplisafe/atatClient'
import { exists, window } from 'browser-monads-ts'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Maybe } from 'monet'
import cond from 'ramda/src/cond'
import propEq from 'ramda/src/propEq'
import Cookies, { CookieSetOptions } from 'universal-cookie'

import {
  COOKIE_ATAT_TOKEN,
  COOKIE_VID,
  getAtAtToken,
  getVisitorId
} from './cookies'

const cookies = new Cookies()

export const handleAtAtTrackingEvent = (data: TrackingData) => {

  // https://at-at.us-east-1.qa.commerce.simplisafe.com/api/doc
  const trackPageLoad = () => {
    const atatRequestBody = {
      optimizelyVisitor: {}, // TODO is this still needed?
      qq: data.pagePath?.replace(/^\/+|\/+$/g, ''), // strip leading and trailing slashes
      query: data.queryString,
      referer: data.referrer, // 'referer' in key is intentional
      title: data.pageTitle,
      uid: data.userId,
      vid: data.visitorId
    }

    const atatRequestParams: atatRequest = {
      body: omitBy(v => isNilOrEmpty(v), atatRequestBody),
      method: 'POST',
      token: getAtAtToken()
    }

    const atatCookieOptions: CookieSetOptions = {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // store for 1 year
      path: '/',
      sameSite: 'strict',
      secure: process.env.NODE_ENV !== 'development' // disable secure for local development only
    }

    // TODO do we need to do anything on failure?
    atatFetch(atatRequestParams)(() => null)(response => {
      safeProp('token', response).forEach(token => {
        !getAtAtToken() && cookies.set(COOKIE_ATAT_TOKEN, token, atatCookieOptions)

        const payload = jwtDecode<JwtPayload>(token)
        safeProp('sub', payload).forEach(vid => {
          !getVisitorId() && cookies.set(COOKIE_VID, vid, atatCookieOptions)
        })
      })
    })
  }

  // Map the current event to the appropriate tracking call
  const track: (data: TrackingData) => void = cond([
    [ propEq('event', 'pageLoad'), trackPageLoad ]
  ])

  track(data)
}

export const visitorIdAtAt =
() => Maybe.fromNull(getAtAtToken()).map(_token => jwtDecode<JwtPayload>(_token))
  .chain(safeProp('sub'))
  .orUndefined()

const pollForAtAtToken = (resolve: (_value: string) => void) => {
  const clear = (interval: NodeJS.Timeout, value: string) => {
    clearInterval(interval)
    resolve(value)
  }

  const interval = setInterval(() => {
    const atatToken = getAtAtToken()
    !!atatToken && clear(interval, atatToken)
  }, 100)
}

/**
 * Polls for the AT-AT cookie and returns a Promise that resolves with the cookie value.
 */
export const fetchAtAtToken = () =>
  new Promise<string | null>(resolve =>
    Maybe.fromFalsy(exists(window))
      .cata(
        () => resolve(undefined),
        () => pollForAtAtToken(resolve)
      )
  )

/**
 * Polls for the AT-AT cookie and returns a Promise that resolves with the cookie's visitor id.
 */
export const fetchAtAtVisitorId = () => fetchAtAtToken().then(() => visitorIdAtAt())
