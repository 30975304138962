import { get, set } from 'local-storage'
import { StringParam, useQueryParams } from 'use-query-params'

import { params } from '../util/queryParams'

const useOptimizelyParams = () => {
  const [ query, setQuery ] = useQueryParams({
    [params.experimentKey]: StringParam,
    [params.variationKey]: StringParam
  })

  const { experiment, variation } = query

  const cachedExperiment = get<string>('experiment')
  const cachedVariation = get<string>('variation')

  experiment && set('experiment', experiment)
  variation && set('variation', variation)

  experiment && variation && setQuery({
    [params.experimentKey]: undefined,
    [params.variationKey]: undefined,
  })

  return {
    experimentKey: experiment || cachedExperiment,
    variationKey: variation || cachedVariation,
  }
}

export default useOptimizelyParams
