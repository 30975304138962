import { createStore } from '@simplisafe/ss-ecomm-data/redux/store'
import PropTypes from 'prop-types'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'

import InitializeStore from '../src/components/InitializeStore'
import TrackingProvider from './TrackingProvider'

const WrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  const store = createStore()

  return (
    <Provider store={store}>
      <CookiesProvider>
        <InitializeStore>
          <TrackingProvider>
            {element}
          </TrackingProvider>
        </InitializeStore>
      </CookiesProvider>
    </Provider>
  )
}

WrapWithProvider.propTypes = { element: PropTypes.element }

export default WrapWithProvider
