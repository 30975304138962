// @ts-strict
import prop from '@simplisafe/ewok/ramda/prop'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import { SSButtonProps } from '@simplisafe/ss-react-components/atoms/SSButton'
import { get as getLocalStorage, set as setLocalStorage } from 'local-storage'
import { Maybe, None } from 'monet'
import applySpec from 'ramda/src/applySpec'
import compose from 'ramda/src/compose'
import defaultTo from 'ramda/src/defaultTo'
import find from 'ramda/src/find'
import has from 'ramda/src/has'
import head from 'ramda/src/head'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import join from 'ramda/src/join'
import juxt from 'ramda/src/juxt'
// TODO replace with ts-functional-pipe
// eslint-disable-next-line no-restricted-imports
import pipe from 'ramda/src/pipe'
import range from 'ramda/src/range'
import reduce from 'ramda/src/reduce'
import split from 'ramda/src/split'
import tail from 'ramda/src/tail'
import toLower from 'ramda/src/toLower'
import when from 'ramda/src/when'
import { ReactNode } from 'react'
import { v4 } from 'uuid'


export const toFirstCharLower = compose(
  join(''),
  juxt([ compose(toLower, head), tail ])
)

type ToButton = ({
  readonly text?: ReactNode
  readonly buttonText?: ReactNode
})

export const toCamelCase = pipe(defaultTo(''), toFirstCharLower, split(' '), join(''))

export const toButton: (a: ToButton) => SSButtonProps = applySpec<SSButtonProps>({
  buttonColor: prop('buttonColor'),
  children: ifElse(has('text'), prop('text'), prop('buttonText')),
  color: pipe(prop('type'), toCamelCase),
  href: prop('url'),
  textColor: prop('textColor'),
})

export const leadingSlashIt = (s: string) => `/${unleadingSlashIt(s)}`
export const unleadingSlashIt = (s: string) => s.replace(/^\/+/, '')
export const untrailingSlashIt = (s: string) => s.replace(/\/+$/, '')

/**
 * When a non-required field is unset in Contentful, its value is returned as null. However, this
 * can end up overriding default values for non-required properties on the underlying components.
 * This function returns undefined when passed null, otherwise it returns the given argument.
 */
export function nullToUndefined<T>(field: T | undefined | null): (T | undefined) {
  return field === null ? undefined : field
}

/**
 * To get the list of previous dates in the format [YYYY-MM]
 *
 * @param noOfMonths number
 * @param prevMonth number
 *
 * @example getPreviousDates(2, 1) if current date is 01/JAN/2021
 * Output: [2020-12, 2020-11]
 */
export const getPreviousDates = (noOfMonths, prevMonth) => {
  const date = new Date()
  // TODO this date mutation is a code smell
  date.setDate(1)
  date.setMonth(date.getMonth() - prevMonth)

  return reduce((acc: ReadonlyArray<string>) => {
    const month = date.getMonth()
    const newDate = `${date.getFullYear()}-${('0' + (month + 1)).slice(-2)}`
    // TODO is this meant to change the date after using the value above?
    // This needs to be refactored to be clearer and not mutate date
    date.setMonth(month - 1)
    return [ ...acc, newDate ]
  }, [], range(0, noOfMonths))
}

/**
 * Returns the first Just in an array of Maybes.
 * If there are no values it returns None'
 *
 * @example
 * findFirstJust([ None(), Just(10), Just(42) ]) // => Just(10)
 */
// this can't be read only since it conflicts with Ramda's type expectation for find
// eslint-disable-next-line functional/prefer-readonly-type
export function findFirstJust<T>(a: Maybe<T>[]) {
  return find<Maybe<T>>((x: Maybe<T>) => x.isSome(), a) || None()
}

/**
 * Returns a camelCase string from any string with spaces
 *
 * @param str string
 *
 * @example
 * strToCamelCase("sOme Weird CasE sTring") => "someWeirdCaseString"
 */
export const strToCamelCase = (str: string) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}


/** To get the time in UTC milliseconds for the given date string. */
export const getTime = (date: string) => new Date(date).getTime()

/** To check the given start and end date is valid or not. */
export const isValidFromToDate = ({ validFrom, validUntil }) => {
  const fromTime = getTime(validFrom)
  const toTime = getTime(validUntil)
  const today = new Date()

  return today.getTime() >= fromTime && today.getTime() <= toTime
}

/**  Remove first letter underscore from object keys */
export const jsonObjectRemoveUnderscore = (dataObject) => {
  Object.keys(dataObject).forEach(key => {
    const formattedKey = key.replace(/^_/, '')
    dataObject[formattedKey] = dataObject[key]
    const deleteOldKey: boolean = formattedKey !== key
    deleteOldKey && delete dataObject[key]
  })
  return dataObject
}

export const setDeviceId = () => {
  when(isNil, () => setLocalStorage('deviceId', v4()))(getLocalStorage('deviceId'))
}

export const getLocalStorageCartIdAsString = () => {
  const localStorageCartId: string | null = getLocalStorage(LOCAL_STORAGE_CARTID)
  return localStorageCartId === null ? '' : localStorageCartId
}
// Replaces "{{months_of_free_service}}" in `linkText` with value passed in `monthsOfFreeService`
export const configureServicePlanTextPlaceholders = (linkText: string, monthsOfFreeService: string): string => {
  const regexMonthOfService = /(?:{{months_of_free_service}})/g
  return linkText.replace(regexMonthOfService, monthsOfFreeService)
}
