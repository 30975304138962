import '../src/assets/fonts/fonts.css'
import '@simplisafe/ss-react-components/styles.css'

import activateBrandRefreshTest from './activateBrandRefresh'
import interceptFetchHeaders from './intercept-fetch-headers'
import wrapWithContext from './wrap-with-context'
import wrapWithProvider from './wrap-with-provider'

export const wrapRootElement = wrapWithProvider
export const wrapPageElement = wrapWithContext

// Registers fetch intercept.
// The return value can be used to deregister like `unregisterFetchHeaders()`.
export const unregisterFetchHeaders = interceptFetchHeaders()

// Called when the Gatsby browser runtime first starts: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
export const onClientEntry = activateBrandRefreshTest
