import React, { FC, ReactNode } from 'react'
import { useTracking } from 'react-tracking'
import handleTrackingEvent from '../src/tracking'

type TrackingProviderProps = {
  readonly children: ReactNode
}

const TrackingProvider: FC<TrackingProviderProps> = ({ children }: TrackingProviderProps) => {
  const { Track } = useTracking({}, { dispatch: handleTrackingEvent })
  return (
    <Track>
      {children}
    </Track>
  )
}

export default TrackingProvider
