import {
  CurrencyCode, LOCALE, Locale, LOCALE_INFO, localeInfo
} from '@simplisafe/ss-ecomm-data/commercetools/locale'

/**
 * Type check if a string is a valid locale.
 */
export const isValidLocale =
  (localeCode: string | Locale | undefined): localeCode is Locale =>
    !!(localeCode && localeInfo[localeCode as Locale])


/**
 * When possible you should get this from Redux with `selectLocale`.
 * This is mostly for build time use outside of the client or outside of
 * React components/hooks.
 */
export const getLocale = (): Locale => LOCALE

export const currencyCode: CurrencyCode = LOCALE_INFO.currency
