import { safeProp } from '@simplisafe/monda'
import fetchIntercept from 'fetch-intercept'

import { fetchAtAtToken } from '../src/tracking/atat'

const getRequestConfig = async (isSSApiRequest: boolean, config) => {
  if (isSSApiRequest) {
    // Wait for the AT-AT cookie to be available before firing off any requests to the SS api
    const atatToken = await fetchAtAtToken()

    const modifiedHeaders = {
      ...safeProp('headers', config).getOrElse({}),
      'Accept-Language': process.env.LOCALE,
      'X-Vid-Token': atatToken
    }

    const modifiedConfig = {
      ...config || {},
      credentials: 'include',
      headers: modifiedHeaders
    }

    return modifiedConfig
  }

  return config
}

/**
 * Intercepts all fetch requests.
 *
 * Targets requests to internal SimpliSafe APIs and modifies the headers.
 */
const interceptFetchHeaders = () => fetchIntercept.register({

  request: async (url, config) => {
    const isSSApiRequest = url.includes(process.env.BASE_SS_API_URL || '')
    const requestConfig = await getRequestConfig(isSSApiRequest, config)

    return [ url, requestConfig ]
  },
})

export default interceptFetchHeaders
