module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-ts-config-virtual-5c01cee50d/0/cache/gatsby-plugin-ts-config-npm-1.1.5-d6754f6277-e5ec35b0fd.zip/node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"./config"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-71b5a1f9e3/0/cache/gatsby-plugin-google-tagmanager-npm-2.7.0-1a1c78aedd-0888d910d6.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"cookieFlags":"SameSite=Strict","dataLayerName":"dataLayer","id":"GTM-MJNZ2VB","includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-use-query-params-virtual-431194be38/0/cache/gatsby-plugin-use-query-params-npm-1.0.1-878a7051ae-62027d9099.zip/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-02226d31f8/0/cache/gatsby-plugin-styled-components-npm-5.5.0-19c69e4697-ab253eff10.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-9486449825/0/cache/gatsby-plugin-manifest-npm-2.4.35-8a6036bc1c-671ab7c279.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","display":"minimal-ui","icon":"src/assets/images/simplisafe-icon.png","name":"SimpliSafe Home Security Systems","short_name":"SimpliSafe","start_url":"/","theme_color":"#008cc1","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9a7c708182eb79e4390678072949d17d"},
    }]
