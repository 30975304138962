// TODO: this should all be temporary and should be ripped out when the brand refresh test is complete.

import { createInstance } from '@optimizely/react-sdk'
import { getLocale } from '@package/utils/locale'
import prop from '@simplisafe/ewok/ramda/prop'
import isNotNil from '@simplisafe/ewok/ramda-adjunct/isNotNil'
import { safePath, safeProp } from '@simplisafe/monda'
import { UserAttributes } from '@simplisafe/ss-ecomm-data/simplisafe/optimizely'
import { logError } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { window } from 'browser-monads-ts'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Maybe } from 'monet'
import { UAParser } from 'ua-parser-js'
import Cookies from 'universal-cookie'

import { getDeployEnv } from '../src/util/deployEnv'
import { get as sessionStorageGet } from '../src/util/session-storage'
// As a postinstall hook, this file is copied from packages/site/optimizelyDatafile.json,
// and at build time, it's populated with real data from Optimizely.
import datafile from './optimizelyDatafile.json'

const SESSION_STORAGE_FIRST_SEEN_URL = 'firstSeenUrl'
const COOKIE_ATAT_TOKEN = 'atat_v2'
const COOKIE_SS_ROUTE = 'ss_route'
const COOKIE_OPTIMIZELY_EXCLUDED = 'optimizely_excluded'

/**
 * Copy of visitorIdAtAt from tracking/atat.ts because importing from there apparently breaks fetch-intercept
 */
const visitorIdAtAt = () => Maybe.fromNull(new Cookies().get<string>(COOKIE_ATAT_TOKEN))
  .map(_token => jwtDecode<JwtPayload>(_token))
  .chain(safeProp('sub'))
  .orUndefined()

/**
 * Copy of getFirstSeenUrl from tracking/optimizely.ts because importing from there apparently breaks fetch-intercept
 */
const getFirstSeenUrl = (vid?: string) => {
  const firstSeenUrl = sessionStorageGet(SESSION_STORAGE_FIRST_SEEN_URL)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
  const vidToFirstSeenUrl: Record<string, string> = firstSeenUrl ? JSON.parse(firstSeenUrl) : {}
  return prop(vid, vidToFirstSeenUrl) || null
}

/**
 * Copy of userAttributes from tracking/optimizely.ts because importing from there apparently breaks fetch-intercept
 */
const userAttributes = (): UserAttributes => {
  const userAgentParser = new UAParser()
  const userAgentData = userAgentParser.getResult()

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    device: safePath([ 'device', 'type' ], userAgentData).getOrElse('desktop'), // UAParser doesn't return type for desktop devices, so defaulting it to 'desktop' if there is no value for type
    environment: getDeployEnv() === 'prd' ? 'production' : 'development',
    firstSeenUrl: getFirstSeenUrl(visitorIdAtAt()) || window.location.pathname,
    geoLocation: getLocale(),
    isLoggedIn: isNotNil(new Cookies().get('auth0.is.authenticated')),
    platform: 'fcp',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- legacy code
    userAgent: safePath([ 'browser', 'name' ], userAgentData).orNull()
  }
}

const activateBrandRefreshTest = () => {
  const cookies = new Cookies()
  const ssRoute = cookies.get<string>(COOKIE_SS_ROUTE)
  const isExcluded = cookies.get<string>(COOKIE_OPTIMIZELY_EXCLUDED)
  const userId = visitorIdAtAt()

  const experimentKey = 'all___site___brand_refresh_test'
  const isVariation = !!ssRoute && /fcpnew\d*/.test(ssRoute)
  const isControl = !!ssRoute && /fcp\d*/.test(ssRoute)
  const variationId = ssRoute ? (isVariation ? 'variation_1' : isControl ? 'control' : null) : null
  const shouldActivate = variationId && !isExcluded

  const optimizelyInstance = createInstance({
    datafile,
    datafileOptions: { autoUpdate: false },
    // don't batch events
    eventBatchSize: 1,
    eventFlushInterval: 1
  })

  shouldActivate && !userId && logError(Error('Brand refresh test: atat_v2 cookie not present when page initialized, skipping activation'))

  shouldActivate && userId && optimizelyInstance.setForcedVariation(experimentKey, userId, variationId)
  shouldActivate && userId && optimizelyInstance.activate(experimentKey, userId, userAttributes())
}

export default activateBrandRefreshTest
