import verifyMaybe from '@simplisafe/ewok/monet-utils/verifyMaybe'
import verifyMaybeObj from '@simplisafe/ewok/monet-utils/verifyMaybeObj'
import { safePath } from '@simplisafe/monda'
import { initializeMiniCart } from '@simplisafe/ss-ecomm-data/deprecated/minicart/actions'
import {
  ImmutablePackages, initializePackages, Package
} from '@simplisafe/ss-ecomm-data/packages'
import {
  ImmutableProducts, initializeProducts, Product
} from '@simplisafe/ss-ecomm-data/products'
import {
  IOActivePromotion, IOEvergreenPromotion,
  IOPromoCode, setEvergreenPromotionAction
} from '@simplisafe/ss-ecomm-data/promotions/actions'
import { EvergreenPromotion } from '@simplisafe/ss-ecomm-data/promotions/lib'
import { isImmutable, Map } from 'immutable'
import { Maybe } from 'monet'
import React, {
  FC,
  ReactElement,
  useLayoutEffect
} from 'react'
import { useDispatch } from 'react-redux'

import useOptimizelyParams from '../../hooks/useOptimizelyParams'
import { userAttributes } from '../../tracking/optimizely'

type InizializeStoreProps = {
  readonly children: ReactElement
  readonly products: ImmutableProducts | { readonly [key: string]: Product }
  readonly packages: ImmutablePackages | { readonly [key: string]: Package }
  readonly evergreenPromotion: Maybe<EvergreenPromotion>
}

const PackageInfoPageAnchorId = '#package-info'

const InjectContext: FC<InizializeStoreProps> = ({
  children, products, packages, evergreenPromotion
}: InizializeStoreProps) =>  {
  const dispatch = useDispatch()
  const optimizelyParams = useOptimizelyParams()

  // Using useLayoutEffect to hydrate products, packages, and promotion data before any useEffect hooks run.
  useLayoutEffect(() => {
    // Products and packages seemingly get turned into JS objects at build time.
    // This turns them back into an Immutable Map before adding them to redux.
    dispatch(initializeProducts(isImmutable(products) ? products : Map(products)))
    dispatch(initializePackages(isImmutable(packages) ? packages : Map(packages)))

    // pull the current userAttribute data to send to promo service to properly bucket the user
    const attributes = userAttributes()

    // Gets information about promotions from Contentful
    dispatch(IOActivePromotion(new Date(), attributes, optimizelyParams))
    dispatch(IOEvergreenPromotion(attributes, optimizelyParams))

    // This sets promotion data based on data fetched at build time
    verifyMaybe(evergreenPromotion).forEach(promo => dispatch(setEvergreenPromotionAction(promo)))

    verifyMaybe(evergreenPromotion)
      .chain(verifyMaybeObj)
      .forEach(promo => {
        promo.promoCode.forEach(code => dispatch(IOPromoCode(code)))
        promo.promoCodeWithMonitoring.forEach(code => dispatch(IOPromoCode(code)))
      })

    // Only initialize redux with products from pageContext once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Reset the minicart state on page load/navigate to prevent errors from stale/invalid data when navigating between the BMS and dynamic package pages.
  useLayoutEffect(() => {
    // check if we should clear minicart, don't clear minicart if we are on the package page navigating to #package-info anchor
    // fixes https://simplisafe.atlassian.net/browse/ECP-3325
    const pageAnchorPath =  safePath([ 'props', 'location', 'hash' ], children).chain(x =>
      x === PackageInfoPageAnchorId ? Maybe.Just(x) : Maybe.None() )

    pageAnchorPath.isNone() && dispatch(initializeMiniCart({}))

  })

  return (
    <span>
      {children}
    </span>
  )
}

export default InjectContext
